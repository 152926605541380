{
    "name": "dls-frontend",
    "version": "1.0.19",
    "scripts": {
        "ng": "ng",
        "ng-openapi-gen": "ng-openapi-gen",
        "swagger-get-sia": "node generate-api-usage/sia/swagger-get-sia-proxy.js",
        "ng-openapi-gen-sia": "npm run swagger-get-sia && ng-openapi-gen --config generate-api-usage/sia/ng-openapi-gen.json",
        "start": "npm run ng-openapi-gen && ng serve --host=0.0.0.0",
        "start-sia": "npm run ng-openapi-gen-sia && ng serve --host=0.0.0.0",
        "start:localDev": "npm run ng-openapi-gen && ng serve --configuration=localDev",
        "build": "npm run ng-openapi-gen &&  node prebuild.js && ng build",
        "build:dev": "npm run ng-openapi-gen &&  node prebuild.js && ng build --configuration dev",
        "build:prod": "npm run ng-openapi-gen &&  node prebuild.js && ng build --configuration production",
        "generate:api": "npm run ng-openapi-gen",
        "generate:api-usage": "cd generate-api-usage && npm i && node index.js",
        "test": "ng test",
        "i18n:extract": "ngx-translate-extract -i ./src -o ./src/assets/i18n/de.json -o ./src/assets/i18n/en.json -f namespaced-json -s -n --format-indentation '    '",
        "e2e": "ng e2e",
        "format:write": "prettier --write \"src/**/*{.ts,.json,.css,.scss,.html}\"",
        "cypress:start": "npm run ng-openapi-gen && ng serve",
        "cypress:open": "cypress open",
        "cypress:run": "cypress run",
        "cypress:wait-on": "wait-on http://localhost:4200 && cypress:run",
        "cypress:test": "start-server-and-test cypress:start http://localhost:4200 cypress:run"
    },
    "private": true,
    "dependencies": {
        "@almothafar/angular-signature-pad": "^5.0.1",
        "@angular-eslint/schematics": "^19.1.0",
        "@angular/animations": "^19.1.4",
        "@angular/cdk": "^19.1.3",
        "@angular/common": "^19.1.4",
        "@angular/compiler": "^19.1.4",
        "@angular/core": "^19.1.4",
        "@angular/forms": "^19.1.4",
        "@angular/material": "^19.1.3",
        "@angular/material-luxon-adapter": "^19.1.3",
        "@angular/material-moment-adapter": "^19.1.3",
        "@angular/platform-browser": "^19.1.4",
        "@angular/platform-browser-dynamic": "^19.1.4",
        "@angular/router": "^19.1.4",
        "@messageformat/core": "^3.3.0",
        "@ngneat/dialog": "^5.0.0",
        "@ngneat/overview": "^6.0.0",
        "@ngneat/until-destroy": "^10.0.0",
        "@ngrx/store": "^19.0.0",
        "@ngrx/store-devtools": "^19.0.0",
        "@ngx-formly/bootstrap": "^6.3.2",
        "@ngx-formly/core": "^6.3.2",
        "@ngx-translate/core": "^15.0.0",
        "@ngx-translate/http-loader": "^8.0.0",
        "@zxing/browser": "0.1.5",
        "@zxing/library": "^0.21.0",
        "@zxing/ngx-scanner": "18.0.0",
        "axios": "^1.7.9",
        "blob-util": "^2.0.2",
        "bootstrap": "^5.3.3",
        "dayjs": "^1.11.11",
        "dls-frontend": "file:",
        "heic2any": "^0.0.4",
        "html-to-image": "^1.11.11",
        "luxon": "^3.4.4",
        "magic-bytes.js": "^1.10.0",
        "material-symbols": "^0.18.0",
        "moment": "^2.30.1",
        "ng-http-caching": "^17.0.1",
        "ng-qrcode": "^18.0.0",
        "ngx-extended-pdf-viewer": "^22.3.9",
        "ngx-mat-select-search": "^7.0.6",
        "ngx-skeleton-loader": "^9.0.0",
        "ngx-spinner": "^17.0.0",
        "ngx-toastr": "^18.0.0",
        "ngx-translate-messageformat-compiler": "^7.0.0",
        "node-fetch": "^3.3.2",
        "prettier": "^3.2.5",
        "rxjs": "~7.8.1",
        "signature_pad": "^5.0.2",
        "uuidv4": "^6.2.13",
        "zone.js": "~0.15.0"
    },
    "devDependencies": {
        "@angular-devkit/build-angular": "^19.1.5",
        "@angular-eslint/builder": "^19.1.0",
        "@angular-eslint/eslint-plugin": "19.1.0",
        "@angular-eslint/eslint-plugin-template": "^19.1.0",
        "@angular-eslint/template-parser": "^19.1.0",
        "@angular/cli": "^19.1.5",
        "@angular/compiler-cli": "^19.1.4",
        "@bartholomej/ngx-translate-extract": "^8.0.2",
        "@ngx-formly/schematics": "^6.3.2",
        "@types/imap": "^0.8.40",
        "@types/jasmine": "~5.1.4",
        "@types/luxon": "^3.4.2",
        "@types/node": "^20.17.16",
        "@typescript-eslint/eslint-plugin": "7.11.0",
        "@typescript-eslint/parser": "7.11.0",
        "autoprefixer": "^10.4.19",
        "cypress": "latest",
        "eslint": "^9.3.0",
        "eslint-config-prettier": "^9.1.0",
        "eslint-plugin-prettier": "^5.1.3",
        "imap": "^0.8.17",
        "jasmine-core": "^5.1.2",
        "jasmine-spec-reporter": "~7.0.0",
        "karma": "~6.4.3",
        "karma-chrome-launcher": "~3.2.0",
        "karma-coverage": "~2.2.1",
        "karma-jasmine": "~5.1.0",
        "karma-jasmine-html-reporter": "^2.1.0",
        "ng-openapi-gen": "^0.51.0",
        "npm-run-all": "^4.1.5",
        "postcss": "^8.4.38",
        "prettier-eslint": "^16.3.0",
        "prettier-plugin-tailwindcss": "^0.5.14",
        "start-server-and-test": "^2.0.3",
        "tailwindcss": "^3.4.3",
        "ts-node": "~10.9.2",
        "tslib": "^2.6.2",
        "typescript": "~5.7.3"
    }
}
