import { Environment } from './environment.model';

export const environment: Environment = {
    production: true,
    apiUrl: 'https://dls-dev-api.tsi-poc.de/api/v2',
    version: require('../../package.json').version,
    buildDate: require('../../build.json').buildDate,
    defaultLang: 'de',
    availableLanguages: [
        {
            code: 'de',
            label: 'Deutsch',
        },
        {
            code: 'en',
            label: 'English',
        },
        {
            code: 'uk',
            label: 'Ukrainian',
        },
        {
            code: 'bg',
            label: 'Bulgarian',
        },
        {
            code: 'fr',
            label: 'French',
        },
        {
            code: 'it',
            label: 'Italian',
        },
        {
            code: 'nl',
            label: 'Dutch',
        },
        {
            code: 'pl',
            label: 'Polish',
        },
        {
            code: 'ro',
            label: 'Romanian',
        },
        {
            code: 'ru',
            label: 'Russian',
        },
        {
            code: 'es',
            label: 'Spanish',
        },
        {
            code: 'tr',
            label: 'Turkish',
        },
        {
            code: 'hr',
            label: 'Croatian',
        },
        {
            code: 'hu',
            label: 'Hungarian',
        },
    ],
    externalSignatureUrl: `/external/signature-pad`,
    externalWalletUrl: `/external/wallet-invite`,
    twoFactorEnabled: true,
    languageSwitcher: true,
    maintenance: false,
    maxFileSize: 15728640,
};
