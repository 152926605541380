import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class MaintenanceService implements OnDestroy {
    private maintenanceMode$ = new BehaviorSubject<boolean>(false);
    private configCheckInterval = 5000;
    private configSubscription: Subscription;
    private previousUrl: string = '/';

    constructor(
        private http: HttpClient,
        private router: Router
    ) {
        this.startConfigCheck();
        
        // Store the current URL before maintenance redirects
        this.router.events.subscribe((event) => {
            if (event.constructor.name === "NavigationEnd") {
                if (!this.maintenanceMode$.value && !this.router.url.includes('/maintenance')) {
                    this.previousUrl = this.router.url;
                }
            }
        });
    }

    private startConfigCheck(): void {
        this.configSubscription = interval(this.configCheckInterval)
            .subscribe(() => this.checkConfig());
    }

    private async checkConfig(): Promise<void> {
        try {
            const config = await firstValueFrom(
                this.http.get<any>(
                    `assets/config.json?v=${new Date().getTime()}`
                )
            );

            let maintenance = config.maintenance;
            if (maintenance === '${MAINTENANCE}') {
                maintenance = environment.maintenance;
            } else {
                maintenance = maintenance === 'true';
            }

            if (this.maintenanceMode$.value !== maintenance) {
                this.setMaintenanceMode(maintenance);
                
                if (maintenance) {
                    this.router.navigate(['/maintenance']);
                } else {
                    // Return to previous page or default route
                    this.router.navigate([this.previousUrl || '/']);
                }
            }
        } catch (error) {
            console.error('Error checking maintenance config:', error);
        }
    }

    getMaintenanceMode() {
        return this.maintenanceMode$.asObservable();
    }

    setMaintenanceMode(maintenance: boolean): void {
        this.maintenanceMode$.next(maintenance);
    }

    getPreviousUrl(): string {
        return this.previousUrl;
    }

    ngOnDestroy() {
        if (this.configSubscription) {
            this.configSubscription.unsubscribe();
        }
    }
}